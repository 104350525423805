/* COLORS */
$cl-white: #FFFFFF;
$cl-gray-500: #D7D8D9;
$cl-gray-800: #2F3744;
$cl-gray-700: #717578;
$cl-gray-500: #D7D8D9;
$cl-gray-400: #E4E5E6;

$cl-background-light: #EFEFEF;
$cl-background-dark: #2F3744;

$cl-font-gray: #A7A7A7;

/* FONTS */
$fnt-open-sans: 'Open Sans', sans-serif;
$fnt-poppins: 'Poppins', sans-serif;
$fnt-dm-serif: 'DM Serif Display', serif;
$fnt-playfair: 'Playfair Display', sans-serif;
