@use '../abstracts/variables' as *;

.footer {
  padding: 87px 105px 51px 105px;
  color: $cl-white;
  background-color: $cl-background-dark;

  @media only screen and (max-width: 900px) {
    padding: 87px 50px 51px 50px;
  }

  @media only screen and (max-width: 600px) {
    padding: 87px 20px 51px 20px;
  }
}

.footer__content {
  display: grid;
  grid-template-columns: 126px 1fr;
  gap: 90px;
  padding-bottom: 65px;
  border-bottom: 1px solid #444E5F;

  @media screen and (max-width: 1300px) {
    grid-template-columns: 1fr max-content;
  }

  @media screen and (max-width: 900px) {
    display: block;
  }
}

.footer__left {
  @media screen and (max-width: 900px) {
    margin-bottom: 50px;
  }
}

.footer__logo {
  height: 78px;
}

.footer__menu {
  display: inline-flex;
  flex-direction: column;
  gap: 20px;
  margin-top: 6px;

  @media screen and (max-width: 900px) {
    margin-bottom: 40px;
  }
}

.footer__menu-item {
  font-weight: 700;
  text-transform: uppercase;

  &:hover {
    color: $cl-white !important;
  }

  > a {
    text-decoration: none;
  }
}

.footer__right {
  display: grid;
  grid-template-columns: 1fr min-content min-content;
  gap: 76px;

  @media screen and (max-width: 1300px) {
    grid-template-columns: 1fr 1fr;
  }

  @media screen and (max-width: 900px) {
    display: block;
  }
}

.footer__contact {
  @media screen and (max-width: 900px) {
    margin-bottom: 40px;
  }
}

.footer__title {
  margin-bottom: 6px;
  font-size: 24px;
  line-height: 36px;
}

.footer__subtitle {
  margin-bottom: 6px;
  font-weight: 600;
}

.footer__contact-block {
  &:not(:last-child) {
    margin-bottom: 17px;
  }
}

.footer__sign-in {
  @media screen and (max-width: 1300px) {
    grid-column-start: 1;
    grid-column-end: 3;
    grid-row-start: 2;
    grid-row-end: 2;
  }
}

.footer__sign-in-form {
  display: flex;
  gap: 4px;
  margin-top: 38px;

  @media screen and (max-width: 600px) {
    flex-direction: column;
    gap: 15px;
  }
}

.footer__input {
  height: 60px;
  width: 290px;
  padding: 0 20px;
  border: 1px solid #717578;
  border-radius: 58px;
}

.footer__btn {
  width: 195px;
}

.footer__bottom {
  display: flex;
  justify-content: space-between;
  padding-top: 38px;
  color: #A3A3A3;

  @media screen and (max-width: 900px) {
    display: block;
    text-align: center;
  }
}

.footer__disclaimer {
  @media screen and (max-width: 900px) {
    margin-bottom: 20px;
  }
}

.footer__dislaimer-menu {
  display: flex;
  gap: 40px;

  @media screen and (max-width: 900px) {
    display: block;
  }
}
