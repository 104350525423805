@use '../abstracts/variables' as *;
@use '../abstracts/mixins' as *;

.hero-splitted {
  display: grid;
  grid-template-columns: 1fr 1fr;
  position: relative;
  width: 100%;
  min-height: 634px;

  @media only screen and (max-width: 900px) {
    grid-template-columns: 1fr;
  }
}

.hero-splitted__content-wrapper {
  @include content-width;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);

  @media only screen and (max-width: 1300px) {
    padding: 0 100px;
  }

  @media only screen and (max-width: 900px) {
    top: 25%;
    left: 0;
    transform: translate(0, -50%);
    padding: 40px;
  }
}

.hero-splitted__title {
  width: 50%;
  padding-right: 75px;
  font-size: 40px;
  line-height: 47px;

  @media only screen and (max-width: 900px) {
    width: 100%;
    padding-right: 0;
    text-align: center;
  }

  @media only screen and (max-width: 600px) {
    font-size: 30px;
    line-height: 37px;
  }

  &.with-description {
    margin-bottom: 26px;
  }
}

.hero-splitted__description {
  width: 50%;
  margin-bottom: 26px;
  padding-right: 75px;
  font-size: 18px;
  opacity: .6;

  @media only screen and (max-width: 900px) {
    width: 100%;
    padding-right: 0;
    text-align: center;
  }
}

.hero-splitted__btn-wrapper {
  @media only screen and (max-width: 900px) {
    display: flex;
    justify-content: center;
    width: 100%;
  }
}

.hero-splitted__img {
  height: 100%;
  width: 100%;
  object-fit: cover;
  object-position: center;
}

.hero-splitted__background {
  @media only screen and (max-width: 900px) {
    min-height: 560px;
  }
}
