@use '@styles/abstracts/variables' as *;

.header {
  position: relative;
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 100px;
  padding: 0 105px;

  @media only screen and (max-width: 900px) {
    padding: 0 50px;
  }

  @media only screen and (max-width: 600px) {
    padding: 0 20px;
  }
}

.header__logo {
  height: 78px;
}

.header__nav {
  display: flex;
  align-items: center;
  gap: 80px;

  &.hide {
    display: none;
  }
}

.header__menu {
  display: flex;
  align-items: center;
  gap: 80px;
  color: #2F3744;
}

.header__menu-item {
  text-transform: uppercase;
  font-weight: 700;

  > a {
    text-decoration: none;
  }
}

.header__cart {
  display: flex;
  align-items: center;
}

.header__hamburger {
  z-index: 2;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 36px;
  width: 36px;
  display: none;

  &.show {
    display: flex;
  }

  &.active {
    .header__hamburger-line {
      background: $cl-white;

      &:nth-child(1) {
        transform: rotate(45deg);
        top: 7px;
      }

      &:nth-child(3) {
        transform: rotate(315deg);
      }
    }
  }
}

.header__hamburger-line {
  position: relative;
  width: 30px;
  height: 2px;
  background: $cl-gray-800;
  transition: .2s;

  &:not(:last-child) {
    margin-bottom: 5px;
  }

  &.hide {
    display: none;
  }
}

.header__nav-mobile {
  z-index: 1;
  position: fixed;
  top: 0;
  left: 200vw;
  height: 100vh;
  width: 100vw;
  background: $cl-background-dark;
  color: $cl-white;
  transition: .2s;

  &.active {
    left: 0;
  }
}

.header__logo-mobile {
  position: absolute;
  top: 11px;
  left: 27px;
}

.header__menu-mobile {
  margin-top: 224px;
  text-transform: uppercase;
  font-weight: 700;
  text-align: center;
}

.header__menu-item-mobile {
  font-size: 38px;
  line-height: 51px;

  &:not(:last-child) {
    margin-bottom: 86px;
  }

  a {
    text-decoration: none;
  }
}
