@use '../abstracts/variables' as *;
@use '../abstracts/mixins' as *;

.home {

}

.home__inner {
  @include content-width;

  @media only screen and (max-width: 1300px) {
    padding: 0 100px;
  }

  @media only screen and (max-width: 900px) {
    padding: 0 50px;
  }

  @media only screen and (max-width: 900px) {
    padding: 0 20px;
  }
}

.home__title-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 44px 0 100px 0;
  text-align: center;
}

.home__title-claim {
  display: flex;
  align-items: center;
  gap: 13px;
  margin-bottom: 14px;
}

.home__title {
  font-size: 40px;
  line-height: 60px;
}

.home__subtitle {
  font-size: 18px;
  line-height: 31px;
}

.home__pages-cards {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 43px 75px;
  margin-bottom: 114px;

  @media screen and (max-width: 1300px) {
    grid-template-columns: repeat(2, 1fr);
    gap: 20px 40px;
  }

  @media only screen and (max-width: 900px) {
    grid-template-columns: 1fr;
  }
}

.home__bottom-wrapper {
  margin: 88px 0 60px 0;
}
