@use '../abstracts/variables' as *;
@use '../abstracts/mixins' as *;

.customer-preview {
  padding: 42px 0 132px 0;
}

.customer-preview__inner {
  @include content-width;

  @media only screen and (max-width: 1300px) {
    padding: 0 100px;
  }

  @media only screen and (max-width: 900px) {
    padding: 0 50px;
  }

  @media only screen and (max-width: 600px) {
    padding: 0 12px;
  }
}

.customer-preview__title {
  font-size: 36px;
  line-height: 54px;
  text-align: center;
}

.customer-preview__divider {
  display: block;
  margin: 15px auto 87px auto;
}

.customer-preview__feedbacks {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 75px;

  @media screen and (max-width: 1300px) {
    gap: 30px;
  }

  @media screen and (max-width: 900px) {
    grid-template-columns: 1fr;
  }
}

.customer-preview__feedback-info {
  display: flex;
  align-items: center;
  gap: 26px;
  margin-bottom: 20px;
}

.customer-preview__feedback-image {
  flex: 0 0 auto;
  height: 65px;
  width: 65px;
}

.customer-preview__feedback-name {
  margin-bottom: 6px;
  font-size: 22px;
  line-height: 33px;
}
