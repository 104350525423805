@use '../abstracts/variables' as *;
@use '../abstracts/mixins' as *;

.about__inner {
  @include content-width;
  margin: 132px auto 220px auto;
  
  @media only screen and (max-width: 1300px) {
    margin: 132px 0 220px 0;
    padding: 0 100px;
  }

  @media only screen and (max-width: 900px) {
    padding: 0 50px;
  }

  @media only screen and (max-width: 600px) {
    padding: 0 12px;
  }
}

.about__container {
  text-align: center;

  &:not(:last-child) {
    margin-bottom: 144px;
  }
}

.about__title {
  font-size: 36px;
  line-height: 54px;
  margin-bottom: 15px;
}

.about__divider {
  margin-bottom: 85px;
}

.about__blocks {
  display: flex;
  justify-content: space-between;
  gap: 60px;

  @media only screen and (max-width: 1300px) {
    gap: 30px;
  }

  @media only screen and (max-width: 900px) {
    flex-direction: column;
    align-items: center;
  }
}

.about__logos {
  display: grid;
  grid-template-columns: 2fr 1fr 2fr 2fr;
  align-items: center;
  gap: 60px;
  max-width: 1000px;
  margin: 0 auto;

  @media only screen and (max-width: 1300px) {
    gap: 30px;
  }

  @media only screen and (max-width: 900px) {
    grid-template-columns: 1fr;
    justify-items: center;
    gap: 50px;
  }
}
