@use '../abstracts/variables' as *;
@use '../abstracts/mixins' as *;

.product {
  padding: 67px 0 149px 0;
}

.product__inner {
  @include content-width;
  
  @media only screen and (max-width: 1300px) {
    padding: 0 100px;
  }

  @media only screen and (max-width: 1300px) {
    padding: 0 50px;
  }

  @media only screen and (max-width: 1300px) {
    padding: 0 20px;
  }
}

.product__title,
.product__description {
  text-align: center;
}

.product__title {
  margin-bottom: 6px;
  font-size: 40px;
  line-height: 60px;
}

.product__description {
  font-size: 18px;
  color: $cl-gray-700;
}

.product__list {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 113px 75px;
  padding-top: 110px;

  @media only screen and (max-width: 1300px) {
    grid-template-columns: repeat(2, 1fr);
  }

  @media only screen and (max-width: 900px) {
    grid-template-columns: 1fr;
    justify-items: center;
  }
}
