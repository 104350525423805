@use '../abstracts/variables' as *;
@use '../abstracts/mixins' as *;

.customer-reviews {
  
}

.customer-reviews__title {
  margin-bottom: 32px;
  font-size: 36px;
  line-height: 54px;
  text-align: center;
}

.customer-reviews__panel,
.customer-reviews__panel-rating,
.customer-reviews__panel-sort {
  display: flex;
  align-items: center;
}

.customer-reviews__panel {
  justify-content: space-between;
  padding-bottom: 42px;
  border-bottom: 1px solid $cl-gray-500;
}

.customer-reviews__panel-rating {
  gap: 16px;
}

.customer-reviews__panel-sort {
  gap: 13px;
}

.customer-reviews__item {
  padding: 20px 0;
  border-bottom: 1px solid $cl-gray-500;
}

.customer-reviews__content {
  display: flex;
  justify-content: space-between;
  margin-bottom: 18px;
}

.customer-reviews__content-left {
  display: flex;
  align-items: center;
  gap: 26px;
}

.customer-reviews__image {
  flex: 0 0 auto;
  height: 65px;
  width: 65px;
}

.customer-reviews__top-container {

}

.customer-reviews__name {
  font-size: 22px;
  line-height: 33px;
}

.customer-reviews__rating {

}

.customer-reviews__content-right {
  font-size: 14px;
}

.customer-reviews__age {
  font-size: 14px;
  margin-bottom: 16px;
}

.customer-reviews__desription {
  margin-bottom: 16px;
}

.customer-reviews__bottom {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.customer-reviews__share {
  display: flex;
  align-items: center;
  gap: 8px;
}

.customer-reviews__feedback {
  display: flex;
  align-items: center;
  gap: 50px;
  font-size: 14px;
  color: $cl-gray-700;
}

.customer-reviews__feedback-buttons {
  display: flex;
  gap: 30px;
}

.customer-reviews__feedback-btn {
  display: flex;
  align-items: center;
  gap: 5px;
}

.customer-reviews__pagination {
  display: block;
  margin: 32px auto;
}
