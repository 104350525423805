@use '../abstracts/variables' as *;
@use '../abstracts/mixins' as *;

.story {
  position: relative;
  height: 710px;

  @media only screen and (max-width: 950px) {
    height: auto;
  }
}

.story__background {
  z-index: -1;
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  object-fit: cover;
}

.story__inner {
  @include content-width;
  display: flex;
  gap: 74px;
  padding-top: 115px;

  @media only screen and (max-width: 1300px) {
    padding: 115px 100px 0 100px;
  }

  @media only screen and (max-width: 950px) {
    flex-direction: column;
    align-items: center;
    text-align: center;
    padding: 115px 100px;
  }

  @media only screen and (max-width: 900px) {
    padding: 115px 50px;
  }

  @media only screen and (max-width: 600px) {
    padding: 115px 20px;
  }
}

.story__image {
  flex: 0 0 auto;
  height: 442px;
  width: 442px;
  object-fit: contain;

  @media screen and (max-width: 1300px) {
    height: 300px;
    width: 300px;
  }
}

.story__content {
  max-width: 600px;
  padding-top: 24px;
}

.story__profile {
  margin-bottom: 21px;
  font-size: 23px;
  line-height: 31px;
  font-weight: 600;
  opacity: .6;
}

.story__title {
  margin-bottom: 51px;
  font-size: 40px;
  line-height: 60px;
  font-weight: 600;
}
