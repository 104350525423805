@use '../abstracts/variables' as *;
@use '../abstracts/mixins' as *;

.features {
  &:not(:last-child) {
    margin-bottom: 20px;
  }

  &:last-child {
    margin-bottom: 40px;
  }
}

.features__title {
  margin-bottom: 20px;
  font-size: 24px;
  line-height: 36px;

  @media only screen and (max-width: 600px) {
    text-align: center;
  }
}

.features__container {
  display: grid;
  grid-template-columns: repeat(3, 1fr);

  @media only screen and (max-width: 600px) {
    grid-template-columns: 1fr;
  }
}

.features__item {
  position: relative;
  text-align: center;
  padding: 20px;

  &::after {
    content: '';
    position: absolute;
    top: 50%;
    right: 0;
    transform: translateY(-50%);
    display: block;
    height: calc(100% - 40px);
    width: 1px;
    background-color: $cl-gray-400;

    @media only screen and (max-width: 600px) {
      content: unset;
    }
  }

  @media only screen and (max-width: 600px) {
    &:not(:last-child) {
      &::after {
        content: '';
        position: absolute;
        left: 50%;
        top: 100%;
        transform: translateX(-50%);
        display: block;
        width: 103px;
        height: 1px;
        background-color: $cl-gray-400;
      }
    }
  }

  &:first-child {
    &::before {
      content: '';
      position: absolute;
      top: 50%;
      left: 0;
      transform: translateY(-50%);
      display: block;
      height: calc(100% - 40px);
      width: 1px;
      background-color: $cl-gray-400;

      @media only screen and (max-width: 600px) {
        content: unset;
      }
    }
  }
}

.features__icon-container {
  display: grid;
  place-items: center;
  height: 24px;
  margin-bottom: 20px;
}

.features__text {
  color: $cl-gray-700;
}
