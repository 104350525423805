@use '../abstracts/variables' as *;
@use '../abstracts/mixins' as *;

.page-card {
  display: block;
  width: 100%;
  border-radius: 12px;
  text-decoration: none;
  box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.1);
  overflow: hidden;
}

.page-card__image {
  display: block;
  height: 419px;
  width: 100%;
  object-fit: cover;
}

.page-card__content {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 86px;
  width: 100%;
  padding: 0 16px 0 22px;
}

.page-card__title {
  font-size: 22px;
  line-height: 33px;
}

.page-card__button {
  display: grid;
  place-items: center;
  height: 48px;
  width: 48px;
  border-radius: 50%;
  background-color: $cl-background-dark;
}
