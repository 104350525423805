@use '@styles/abstracts/variables' as *;

body {
  font-family: $fnt-open-sans;
  font-weight: 400;
  font-size: 16px; 
  line-height: 26px;
  color: $cl-gray-800;
  background-color: $cl-white;

  &.no-scroll {
    overflow: hidden;
  }
}

a, button {
  cursor: pointer;
}

a {
  text-decoration: underline;

  &:hover {
    text-decoration: none;
  }
}

strong {
  font-weight: 600;
}
