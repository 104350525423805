@use '../abstracts/variables' as *;

.btn {
  display: inline-flex;
  justify-content: center;
  align-items: center;
  height: 60px;
  padding: 0 42px;
  border-radius: 115px;
  font-size: 18px;
  line-height: 27px;
  text-decoration: none;
  color: $cl-white;
  background: $cl-background-dark;

  &--blue-accent {
    background: linear-gradient(127.93deg, #435BFF -2.9%, #0038E5 96.02%);

    &.btn--square {
      background: $cl-background-dark;

      &:hover {
        background: linear-gradient(127.93deg, #435BFF -2.9%, #0038E5 96.02%);
      }
    }
  }

  &--square {
    display: grid;
    place-items: center;
    height: 48px;
    width: 48px;
    padding: 0;
  }
}
