@use '../abstracts/variables' as *;
@use '../abstracts/mixins' as *;

.hero {
  position: relative;
  width: 100%;
  height: calc(100vh - 100px);
  min-height: 550px;

  @media only screen and (max-width: 900px) {
    display: flex;
    flex-direction: column;
    justify-content: center;
    height: auto;
  }
}

.hero__inner {
  height: 100%;

  @media only screen and (max-width: 900px) {
    order: -1;
    width: 100%;
  }
}

.hero__img {
  z-index: -1;
  position: absolute;
  height: 100%;
  width: 100%;
  object-fit: cover;
  object-position: center;
}

.hero__blur {
  z-index: -1;
  position: absolute;
  top: 0;
  width: 60%;
  height: 100%;

  &--mobile-only {
    @media only screen and (min-width: 900px) {
      background: initial !important;
      backdrop-filter: initial !important;
    }
  }

  @media only screen and (max-width: 900px) {
    width: 100%;
  }
}

.hero__content {
  @include content-width;
  display: grid;
  grid-template-columns: 7fr 6fr;
  align-items: center;
  height: 100%;

  @media only screen and (max-width: 1300px) {
    padding: 0 100px;
  }

  @media only screen and (max-width: 900px) {
    grid-template-columns: 1fr;
    padding: 0 50px;
  }

  &--light-text {
    color: $cl-white;

    .hero__description {
      color: $cl-gray-500;
    }
  }

  &--blur-on-mobile {
    @media only screen and (max-width: 900px) {
      color: $cl-white;
      
      .hero__description {
        color: $cl-gray-500;
      }
    }
  }
}

.hero__title {
  max-width: 609px;
  margin-bottom: 26px;
  font-size: 40px;
  line-height: 47px;
}

.hero__description {
  max-width: 486px;
  margin-bottom: 26px;
  font-size: 18px;
  opacity: .6;

  strong {
    font-weight: 700;
  }
}
