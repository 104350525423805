@use '../abstracts/variables' as *;

.about-block {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 400px;
  max-width: 310px;
  text-align: center;

  @media only screen and (max-width: 1300px) {
    gap: 30px;
    height: auto;
  }
}

.about-block__image-wrapper {
  display: grid;
  place-items: center;
  width: 100%;
  height: 120px;
  margin-bottom: 40px;
}

.about-block__title {
  margin-bottom: 15px;
  font-size: 24px;
  line-height: 30px;
}

.about-block__description {
  font-size: 14px;
  line-height: 24px;
}
