@use '../abstracts/variables' as *;
@use '../abstracts/mixins' as *;

.detail {
  padding-top: 77px;
}

.detail__inner {
  @include content-width;

  @media only screen and (max-width: 1300px) {
    padding: 0 100px;
  }

  @media only screen and (max-width: 900px) {
    padding: 0 50px;
  }

  @media only screen and (max-width: 600px) {
    padding: 0 12px;
  }
}

.detail__breadcrumb {
  display: flex;
  align-items: center;
  gap: 10px;
  margin-bottom: 28px;
  color: $cl-gray-700;
}

.detail__breadcrumb-separator {
  display: inline-block;
  height: 4px;
  width: 4px;
  background-color: #5E4DC1;
}

.detail__container {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 59px;
  margin-bottom: 126px;

  @media only screen and (max-width: 1300px) {
    grid-template-columns: 1fr;
    justify-items: center;
  }
}

.detail__images {
  max-width: 540px;
}

.detail__images-secondary {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 33px;
}

.detail__rating {
  display: flex;
  align-items: center;
  gap: 16px;
  margin-bottom: 20px;
}

.detail__title {
  font-size: 30px;
  line-height: 40px;
  margin-bottom: 8px;
}

.detail__price {
  margin-bottom: 40px;
  font-family: $fnt-dm-serif;
  font-size: 36px;
  line-height: 54px;
  font-weight: 400;
}

.detail__cta-container {
  display: flex;
  gap: 6px;
  margin-bottom: 40px;
}

.detail__amount {
  display: flex;
  align-items: center;
  gap: 38px;
  padding: 20px 24px;
}

.detail__amount-btn {
  flex: 0 0 auto;
  display: flex;
  align-items: center;
}

.detail__buy-btn {
  flex: 1;
  justify-content: center;
}

.detail__product-info {
  margin-bottom: 40px;
  color: $cl-gray-700;
}

.detail__product-info-title {
  margin-bottom: 20px;
  font-size: 24px;
  line-height: 36px;
  color: $cl-gray-800;
}

.detail__product-info-description {
  font-size: 18px;
}

.detail__product-info-description-paragraph {
  margin-bottom: 26px;
}

.detail__product-info-features {

}

.detail__product-info-feature {
  position: relative;
  margin-left: 24px;

  &::before {
    content: '';
    position: absolute;
    top: 50%;
    left: -24px;
    transform: translateY(-50%);
    height: 7px;
    width: 7px;
    background-color: $cl-gray-800;
    border-radius: 50%;
  }

  &:not(:last-child) {
    margin-bottom: 20px;
  }
}

.detail__additionalFeatures-item {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 74px;
  padding-right: 30px;
  border-bottom: 1px solid $cl-gray-800;
  font-size: 18px;

  &:first-child {
    border-top: 1px solid $cl-gray-800;
  }
}

.detail__hero {
  position: relative;
  height: 634px;
  width: 100%;
  color: $cl-white;
}

.detail__hero-image {
  z-index: -1;
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  object-fit: cover;
}
