@use '../abstracts/variables' as *;

.product-item {
  max-width: 450px;
}

.product-item__info {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 18px;
  padding: 0 26px;
}

.product-item__title {
  font-size: 18px;
}

.product-item__price {
  font-family: $fnt-playfair;
  font-size: 26px;
  font-weight: 600;
}

.product-item__btn {
  flex: 0 0 auto;
}
