@use '../abstracts/variables' as *;
@use '../abstracts/mixins' as *;

.product-preview {

}

.product-preview__inner {
  @include content-width;
  padding: 42px 0 132px 0;

  @media only screen and (max-width: 1300px) {
    padding: 42px 100px 132px 100px;
  }

  @media only screen and (max-width: 900px) {
    padding: 42px 50px 132px 50px;
  }

  @media only screen and (max-width: 900px) {
    padding: 42px 20px 132px 20px;
  }
}

.product-preview__header {
  display: flex;
  justify-content: space-between;
  align-items: center;

  @media only screen and (max-width: 600px) {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 30px;
  }
}

.product-preview__title {
  font-size: 30px;
  line-height: 45px;
}

.product-preview__all-btn {
  display: flex;
  align-items: center;
  gap: 20px;
  height: 25px;
  text-decoration: none;
  font-size: 18px;
}

.product-preview__list {
  display: flex;
  justify-content: space-between;

  @media only screen and (max-width: 900px) {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
}
