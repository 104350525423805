@use '../abstracts/variables' as *;

.fnt-poppins {
  font-family: $fnt-poppins;
  font-weight: 600;
}

.fnt-dm-serif {
  font-family: $fnt-dm-serif;
  font-weight: 400;
}
