@use '../abstracts/variables' as *;
@use '../abstracts/mixins' as *;

.connect {
  padding: 36px 0 47px 0;
  text-align: center
}

.connect__inner {
  @include content-width;
  
  @media only screen and (max-width: 1300px) {
    padding: 0 100px;
  }

  @media only screen and (max-width: 900px) {
    padding: 0 50px;
  }

  @media only screen and (max-width: 600px) {
    padding: 0 12px;
  }
}

.connect__title {
  margin-bottom: 15px;
  font-size: 36px;
  line-height: 54px;
}

.connect__text {
  margin-bottom: 32px;
}

.connect__images {
  display: grid;
  grid-template-columns: repeat(6, 1fr);
  gap: 6px;

  @media only screen and (max-width: 900px) {
    grid-template-columns: repeat(3, 1fr);
  }
}
